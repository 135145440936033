import React from "react";
import styled from "styled-components";

const LugarContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center; /* Centra horizontalmente el contenido */
	justify-content: center; /* Centra verticalmente el contenido */
	height: 100vh; /* Asegura que el contenedor ocupe toda la altura disponible */
	width: 100%;
`;

const LugarText = styled.div`
	font-family: "Great Vibes", cursive;
	color: white;
	text-align: center;
	font-size: 1.2rem;
	padding: 10px;
	line-height: 1.2;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
	max-width: 80%;
`;

const HourText = styled.div`
	font-family: "Great Vibes", cursive;
	color: white;
	text-align: center;
	font-size: 2.5rem;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
	max-width: 80%;
`;

const CenteredImage = styled.img`
	width: 60px; /* Ajusta el tamaño de la imagen según sea necesario */
	margin-bottom: 5px;
`;

const InlineImage = styled.img`
	width: 60px; /* Ajusta el tamaño de la imagen según sea necesario */
	margin-top: 5px; /* Espacio entre el texto y la segunda imagen */
	margin-bottom: 5px; /* Espacio entre el texto y la segunda imagen */
`;

const Lugar = () => {
	return (
		<LugarContainer>
			<CenteredImage src="/assets/ubicacion.png" alt="Ubicación del Evento" />
			<LugarText>
				La recepción se llevará a cabo en Rancho Monte Claro en Desamparados.
				<br />
				<a
					href="https://ul.waze.com/ul?ll=9.89785478%2C-84.07159925&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"
					target="_blank"
					style={{ color: "gold", textDecoration: "underline" }}
					rel="noreferrer">
					<br />
					¿Necesitas ayuda para llegar?
				</a>
			</LugarText>
			<InlineImage src="/assets/hora.png" alt="Hora del Evento" />
			<HourText>2:30pm</HourText>
			<HourText>22 de Setiembre</HourText>
			<LugarText>
				"Por respeto a la ceremonia de presentación, les pedimos amablemente que lleguen puntualmente. Una vez iniciada la ceremonia, no será
				posible permitir el acceso para evitar interrupciones. Agradecemos su comprensión y colaboración."
			</LugarText>
		</LugarContainer>
	);
};

export default Lugar;
