// src/components/Inicio.js
import React, { useState, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import styled, { keyframes } from "styled-components";
import { toast } from "react-toastify";
import Modal from "react-modal";
import axios from "axios";

Modal.setAppElement("#root");

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100vh;
	padding-top: 5vh;
`;

const PhotoFrame = styled.img`
	width: 60vh;
	text-shadow: 6px 6px 10px rgba(0, 0, 0, 0.7);
`;

const WelcomeText = styled.div`
	font-family: "Great Vibes", cursive;
	color: white;
	text-align: center;
	font-size: 2rem;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
	margin-top: -8vh;
`;

const Countdown = styled.div`
	font-family: "Great Vibes", cursive;
	color: white;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);

	@media (max-width: 600px) {
		font-size: 2rem;
	}
`;

const bounceAnimation = keyframes`
  0%, 20%, 50%, 80%, 100% { 
    transform: translateY(0); 
  }
  40% { 
    transform: translateY(-10px); 
  }
  60% { 
    transform: translateY(-5px); 
  }
`;

const FloatingButton = styled.img`
	width: 80px;
	cursor: pointer;
	animation: ${bounceAnimation} 2s infinite;
`;

// const ModalContent = styled.div`
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	justify-content: center;
// 	color: black;
// 	background-color: white;
// 	padding: 20px;
// 	border-radius: 10px;
// 	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
// 	position: relative;
// 	width: 600px;
// 	height: 400px;
// `;

// const TableContainer = styled.div`
// 	position: relative;
// 	width: 100%;
// 	height: 100%;
// `;

const Table = styled.div`
	width: 80px;
	height: 80px;
	background-color: #f0c14b;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
	position: absolute;
`;

const Seat = styled.div`
	position: absolute;
	width: 15px;
	height: 15px;
	background-color: #ffffff;
	border-radius: 50%;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.6rem;
	color: black;
`;

const CloseButton = styled.button`
	position: absolute;
	top: 20px;
	right: 20px;
	background: transparent;
	border: none;
	font-size: 1.5rem;
	color: white;
	cursor: pointer;
`;

const InputField = styled.input`
	width: 80%;
	padding: 10px;
	margin-top: 5px;
	border-radius: 5px;
	border: 2px solid gold; /* Borde dorado */
	background-color: transparent; /* Fondo transparente */
	color: white; /* Letra blanca */
	::placeholder {
		color: rgba(255, 255, 255, 0.7); /* Placeholder en blanco con opacidad */
	}
	font-family: "Great Vibes", cursive;
	font-size: 1rem;
`;

const SaveButton = styled.img`
	padding: 10px 20px;
	border: none;
	width: 60px;
	cursor: pointer;
	animation: ${bounceAnimation} 2s infinite;
`;

const TableContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-left: 20px;
`;

const ModalContent = styled.div`
	background-color: rgba(0, 0, 0, 0.9);
	background-image: url("/assets/bg2.png"); /* Ruta de la imagen de fondo */
	background-size: cover;
	border-radius: 10px;
	color: black;
	width: 100%; /* Se eliminan las comillas */
	height: 90%; /* Se eliminan las comillas */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-position: center;
`;

const Text = styled.div`
	font-family: "Great Vibes", cursive;
	color: white;
	text-align: center;
	font-size: 1.5rem;
	padding: 5px;
	line-height: 1;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
	max-width: 90%;
`;

const Label = styled.div`
	font-family: "Great Vibes", cursive;
	color: white;
	text-align: left;
	font-size: 1rem;
	max-width: 80%;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
`;

const Inicio = () => {
	const calculateTimeLeft = () => {
		const eventDate = new Date("2024-09-22T14:30:00");
		const now = new Date();
		const difference = eventDate - now;

		let timeLeft = {};

		if (difference > 0) {
			timeLeft = {
				days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((difference / 1000 / 60) % 60),
				seconds: Math.floor((difference / 1000) % 60),
			};
		}

		return timeLeft;
	};

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
	const [isModalOpen, setModalOpen] = useState(false);
	const [guestNames, setGuestNames] = useState([]);
	const [qty, setQty] = useState(0);

	const closeModal = () => setModalOpen(false);

	useEffect(() => {
		const interval = setInterval(() => {
			setTimeLeft(calculateTimeLeft());
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		const getQtyFromStorage = async () => {
			// await AsyncStorage.clear();
			const storedQty = await AsyncStorage.getItem("qty");
			const storedGuestNames = await AsyncStorage.getItem("guestNames");

			if (storedQty) {
				const qty = parseInt(storedQty, 10);
				setQty(qty);

				if (storedGuestNames) {
					setGuestNames(JSON.parse(storedGuestNames));
				} else {
					setGuestNames(Array.from({ length: qty }, () => ""));
				}
			}
		};

		getQtyFromStorage();
	}, []);

	const handleInputChange = (index, value) => {
		const updatedNames = [...guestNames];
		updatedNames[index] = value;
		setGuestNames(updatedNames);
	};

	const handleSave = async () => {
		const code = await AsyncStorage.getItem("code");
		await AsyncStorage.setItem("guestNames", JSON.stringify(guestNames));
		closeModal();

		console.log("DATA", code, guestNames);
		try {
			const response = await axios.post("https://mitserver.app:2409/saveGuests", {
				code: code,
				guests: guestNames,
			});

			if (response.status === 200) {
				console.log("Invitados guardados exitosamente");
				toast("Invitados guardados exitosamente", {
					style: {
						fontFamily: "Great Vibes",
						fontSize: "1.5rem",
						backgroundColor: "#556B2F", // Verde musgo
						color: "#FFD700", // Dorado
						textAlign: "center", // Centrar el texto
					},
					progressStyle: {
						background: "#FFD700",
					},
					autoClose: 5000,
				});
			}
		} catch (error) {
			console.error("Error al guardar los invitados:", error);
		}
	};

	const formatTime = (time) => {
		return time < 10 ? `0${time}` : time;
	};

	return (
		<Container>
			<PhotoFrame src="/assets/photoFrame.png" alt="Marco de Fotos" />
			<WelcomeText>¡Bienvenidos a mi celebración especial!</WelcomeText>
			<Countdown>
				{timeLeft.days || 0} días {formatTime(timeLeft.hours || 0)}:{formatTime(timeLeft.minutes || 0)}:{formatTime(timeLeft.seconds || 0)}
			</Countdown>
			<FloatingButton
				src="/assets/seal.png"
				alt="Botón Flotante"
				onClick={() => {
					setModalOpen(true);

					toast("Fecha Limite para confirmar asistencia 01 de Setiembre", {
						style: {
							fontFamily: "Great Vibes",
							fontSize: "1.5rem",
							backgroundColor: "#556B2F", // Verde musgo
							color: "#FFD700", // Dorado
							textAlign: "center", // Centrar el texto
						},
						progressStyle: {
							background: "#FFD700",
						},
						autoClose: 5000,
					});
				}}
			/>

			<Modal
				isOpen={isModalOpen}
				onRequestClose={closeModal}
				style={{
					content: {
						top: "55%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						backgroundColor: "transparent",
						borderRadius: "10px",
						width: "90%",
						height: "100%",
					},
				}}>
				<ModalContent>
					<CloseButton onClick={closeModal}>&times;</CloseButton>
					<Text>Hemos reservado {qty} espacios para ti</Text>
					<TableContainer>
						{Array.from({ length: qty }).map((_, index) => (
							<div key={index} style={{ marginBottom: "10px", width: "130%" }}>
								<Label>Invitado {index + 1}:</Label>
								<InputField
									type="text"
									placeholder={`Nombre completo del invitado ${index + 1}`}
									value={guestNames[index]}
									onChange={(e) => handleInputChange(index, e.target.value)}
								/>
							</div>
						))}
					</TableContainer>

					<SaveButton src="/assets/saveSeal.png" alt="Botón Salvar" onClick={handleSave} />
				</ModalContent>
			</Modal>
		</Container>
	);
};

export default Inicio;
