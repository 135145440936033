// src/components/Vestimenta.js
import React from "react";
import styled from "styled-components";

const VestContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center; /* Centra horizontalmente el contenido */
	justify-content: center; /* Centra verticalmente el contenido */
	height: 100vh;
	width: 100%;
`;

const VestimentaText = styled.div`
	font-family: "Great Vibes", cursive;
	color: white;
	text-align: center;
	font-size: 1.5rem;
	padding: 20px;
	line-height: 1.5;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
	max-width: 80%;
`;

const Image = styled.img`
	width: 100px; /* Ajusta el tamaño de la imagen según sea necesario */
	margin-bottom: 5px;
`;

// Componentes estilizados para cada color
const ColorItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 5px 0;
`;

const ColorCircle = styled.span`
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background-color: ${(props) => props.color};
	margin-right: 10px;
`;

const GreenText = styled.span`
	color: #008000; /* Verde */
`;

const WhiteText = styled.span`
	color: #ffffff; /* Blanco */
`;

const GoldText = styled.span`
	color: #ffd700; /* Dorado */
`;

const Vestimenta = () => {
	return (
		<VestContainer>
			<Image src="/assets/dressCode.png" alt="Código de Vestimenta" />

			<VestimentaText>
				El código de vestimenta es formal. <br /> Nos reservamos los colores:
				<br />
				<ColorItem>
					<ColorCircle color="#008000" />
					<GreenText>Verde</GreenText>
				</ColorItem>
				<ColorItem>
					<ColorCircle color="#FFFFFF" />
					<WhiteText>Blanco</WhiteText>
				</ColorItem>
				<ColorItem>
					<ColorCircle color="#FFD700" />
					<GoldText>Dorado</GoldText>
				</ColorItem>
			</VestimentaText>
		</VestContainer>
	);
};

export default Vestimenta;
