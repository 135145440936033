// src/components/Regalos.js
import React from "react";
import styled from "styled-components";

const GiftContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center; /* Centra horizontalmente el contenido */
	justify-content: center; /* Centra verticalmente el contenido */
	height: 100vh; /* Asegura que el contenedor ocupe toda la altura disponible */
`;

const RegalosText = styled.div`
	font-family: "Great Vibes", cursive;
	color: white;
	text-align: center;
	font-size: 1.5rem;
	padding: 20px;
	line-height: 1.5;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
	max-width: 80%;
`;

const Image = styled.img`
	width: 100px; /* Ajusta el tamaño de la imagen según sea necesario */
	margin-bottom: 5px;
`;

const Regalos = () => {
	return (
		<GiftContainer>
			<Image src="/assets/money.png" alt="Ubicación del Evento" />
			<RegalosText>
				Tu cariño y compañía son muy importantes para mi en este día tan especial, me encantaría que tus muestras de cariño fueran en efectivo
				ya que esto me ayudaría a cumplir una meta muy personal por la cual he estado trabajando.
			</RegalosText>
		</GiftContainer>
	);
};

export default Regalos;
