// src/App.js
import React from "react";
import MainPage from "./components/mainPage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
	return (
		<div>
			<ToastContainer />
			<MainPage />
		</div>
	);
};

export default App;
