// src/components/MainPage.js
import React, { useState, useEffect, useRef } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import styled, { keyframes } from "styled-components";
import { FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import Login from "./login";
import Home from "./home";
import Place from "./place";
import Vest from "./vest";
import Gift from "./gift";
import Photos from "./photos";

import backgroundMusic from "../music/musica.mp3";

const MainPageContainer = styled.div`
	position: relative;
	width: 100vw;
	height: 100vh;
	background-image: url("/assets/bg3.png");
	background-size: cover;
	background-position: center;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	flex-direction: column;
	background-color: "green";
`;

const BorderContainer = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	pointer-events: none;
	background-image: url("ruta/a/tu/borde.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	z-index: 1;
`;

const Title = styled.h1`
	font-size: 6rem;
	font-family: "Great Vibes", cursive;
	color: white;
	text-shadow: 2px 2px 8px gold;
	margin: 0;
	position: absolute;
	top: 8vh;
	transform: translateY(-50%);

	@media (max-width: 600px) {
		font-size: 4rem;
	}
`;

const AdditionalSvgContainer = styled.div`
	width: 100vw;
	height: 100vh;
	background-image: url("/assets/invitation.jpg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	position: relative;
`;

const scaleAnimation = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
`;

const ButtonImage = styled.img`
	position: absolute;
	width: 150px;
	height: 150px;
	bottom: 20px;
	right: 20px;
	cursor: pointer;
	animation: ${scaleAnimation} 2s infinite;
`;

const Footer = styled.footer`
	position: absolute;
	bottom: 3vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 15px;
`;

const MenuButton = styled.img`
	width: ${(props) => (props.active ? "60px" : "50px")};
	height: ${(props) => (props.active ? "60px" : "50px")};
	cursor: pointer;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
	border-radius: 5px;
	transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out, width 0.1s ease-in-out, height 0.1s ease-in-out;

	&:active {
		transform: translateY(2px); /* Mueve el botón hacia abajo para simular un click */
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reduce la sombra para simular el efecto de presión */
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const ButtonText = styled.span`
	font-family: "Great Vibes", cursive;
	color: gold;
	margin-top: 10px;
	text-align: center;
	font-size: 1.2rem;
`;

const ContentContainer = styled.div`
	width: 100%;
	height: 90vh;
	display: flex;
	align-items: center;
	color: white;
	font-size: 2rem;
`;

const HiddenAudioPlayer = styled.audio`
	display: none;
`;

const MuteButton = styled.button`
	position: absolute;
	top: 10px;
	right: 10px;
	background: transparent;
	border: none;
	cursor: pointer;
	color: white;
	font-size: 24px;

	&:hover {
		color: gold;
	}
`;

const MainPage = () => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [activePage, setActivePage] = useState("inicio");
	const audioRef = useRef(null);
	const [isMuted, setIsMuted] = useState(false);

	const toggleMute = () => {
		setIsMuted(!isMuted);
		if (audioRef.current) {
			audioRef.current.muted = !audioRef.current.muted;
		}
	};

	const handleLogin = () => {
		setIsAuthenticated(true);
	};

	// Verificar si 'qty' ya existe en AsyncStorage al montar el componente
	useEffect(() => {
		const checkAuthentication = async () => {
			try {
				const qty = await AsyncStorage.getItem("qty");
				if (qty !== null) {
					setIsAuthenticated(true); // Si qty existe, marcar como autenticado
				}
			} catch (error) {
				console.error("Error al verificar la autenticación:", error);
			}
		};

		checkAuthentication();
	}, []);

	const renderContent = () => {
		switch (activePage) {
			case "lugar":
				return <Place />;
			case "vestimenta":
				return <Vest />;
			case "regalos":
				return <Gift />;
			case "fotos":
				return <Photos />;
			case "inicio":
				return <Home />;
			default:
				return <Home />;
		}
	};

	if (!isAuthenticated) {
		return <Login onLogin={handleLogin} />; // Muestra la página de inicio de sesión si no está autenticado
	}

	return (
		<>
			<MainPageContainer>
				<BorderContainer />
				<Title>XV Violet</Title>
				<ContentContainer>{renderContent()}</ContentContainer>
			</MainPageContainer>
			<Footer>
				<ButtonContainer>
					<MenuButton
						src="/assets/menuButton.png"
						alt="Botón de Menú"
						active={activePage === "lugar"}
						onClick={() => setActivePage("lugar")}
					/>
					<ButtonText>Lugar</ButtonText>
				</ButtonContainer>
				<ButtonContainer>
					<MenuButton
						src="/assets/menuButton.png"
						alt="Botón de Menú"
						active={activePage === "vestimenta"}
						onClick={() => setActivePage("vestimenta")}
					/>
					<ButtonText>Vestimenta</ButtonText>
				</ButtonContainer>
				<ButtonContainer>
					<MenuButton
						src="/assets/menuButton.png"
						alt="Botón de Menú"
						active={activePage === "inicio"}
						onClick={() => setActivePage("inicio")}
					/>
					<ButtonText>Inicio</ButtonText>
				</ButtonContainer>
				<ButtonContainer>
					<MenuButton
						src="/assets/menuButton.png"
						alt="Botón de Menú"
						active={activePage === "regalos"}
						onClick={() => setActivePage("regalos")}
					/>
					<ButtonText>Regalos</ButtonText>
				</ButtonContainer>
				<ButtonContainer>
					<MenuButton
						src="/assets/menuButton.png"
						alt="Botón de Menú"
						active={activePage === "fotos"}
						onClick={() => setActivePage("fotos")}
					/>
					<ButtonText>Fotos</ButtonText>
				</ButtonContainer>
			</Footer>

			<HiddenAudioPlayer ref={audioRef} autoPlay loop>
				<source src={backgroundMusic} type="audio/mp3" />
				Your browser does not support the audio element.
			</HiddenAudioPlayer>

			{/* Botón de mute/unmute */}
			<MuteButton onClick={toggleMute}>{isMuted ? <FaVolumeMute /> : <FaVolumeUp />}</MuteButton>
		</>
	);
};

export default MainPage;
