// src/components/Fotos.js
import React, { useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import styled from "styled-components";
import Modal from "react-modal";
import Gallery from "react-photo-gallery";
import { toast } from "react-toastify";
import Viewer from "react-viewer";
import axios from "axios";

Modal.setAppElement("#root");

const PhotosContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
`;

const FotosText = styled.div`
	font-family: "Great Vibes", cursive;
	color: white;
	text-align: center;
	font-size: 1.5rem;
	padding: 20px;
	line-height: 1.5;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
	max-width: 80%;
`;

const Image = styled.img`
	width: 100px;
	margin-bottom: 5px;
`;

const ButtonContainer = styled.div`
	display: flex;
	width: 300px;
	justify-content: space-between;
	margin-bottom: 20px;
`;

const ThumbnailContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 10px;
	padding: 20px;
`;

const EmptyThumbnail = styled.div`
	width: 100px;
	height: 100px;
	background-color: rgba(255, 255, 255, 0.1);
	border: 2px dashed rgba(255, 255, 255, 0.3);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
	cursor: pointer;
`;

const Thumbnail = styled.img`
	width: 100px;
	height: 100px;
	object-fit: cover;
	border-radius: 5px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
`;

const CloseButton = styled.button`
	position: absolute;
	top: 10px;
	right: 10px;
	background: transparent;
	border: none;
	font-size: 1.5rem;
	color: white;
	cursor: pointer;
`;

const Fotos = () => {
	const [uploadedImages, setUploadedImages] = useState([]);
	const [files, setFiles] = useState([]); // Guardamos los archivos reales
	const [isUploadModalOpen, setUploadModalOpen] = useState(false);
	const [isGalleryModalOpen, setGalleryModalOpen] = useState(false);
	const [currentImage, setCurrentImage] = useState(null);
	const [isViewerOpen, setViewerOpen] = useState(false);

	const openUploadModal = () => setUploadModalOpen(true);
	const closeUploadModal = () => setUploadModalOpen(false);

	const openGalleryModal = () => setGalleryModalOpen(true);
	const closeGalleryModal = () => setGalleryModalOpen(false);

	const handleFileChange = (event) => {
		const selectedFiles = Array.from(event.target.files);
		const storedImagesCount = localStorage.getItem("uploadedImagesCount") || 0;
		const totalFiles = selectedFiles.length + Number(storedImagesCount);

		if (totalFiles > 10) {
			toast("Solo puedes subir hasta 10 imágenes.", {
				style: {
					fontFamily: "Great Vibes",
					fontSize: "1.5rem",
					backgroundColor: "#556B2F", // Verde musgo
					color: "#FFD700", // Dorado
					textAlign: "center", // Centrar el texto
				},
				progressStyle: {
					background: "#FFD700",
				},
				autoClose: 5000,
			});
			return;
		}

		const newImages = selectedFiles.map((file) => URL.createObjectURL(file));
		setUploadedImages((prevImages) => [...prevImages, ...newImages]);
		setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);

		// Actualiza el contador en localStorage
		const updatedCount = Number(storedImagesCount) + selectedFiles.length;
		localStorage.setItem("uploadedImagesCount", updatedCount);
	};

	const handleSubmit = async () => {
		const deadline = new Date(new Date().getFullYear(), 8, 22);
		const code = await AsyncStorage.getItem("code");
		const currentDate = new Date();

		if (currentDate < deadline) {
			toast("!! No se pueden subir imágenes hasta el 22 de septiembre. !!", {
				style: {
					fontFamily: "Great Vibes",
					fontSize: "1.5rem",
					backgroundColor: "#556B2F",
					color: "#FFD700",
					textAlign: "center",
				},
				progressStyle: {
					background: "#FFD700",
				},
				autoClose: 5000,
			});
			return;
		}

		const formData = new FormData();
		files.forEach((file) => {
			formData.append("images", file);
		});
		formData.append("code", code);

		try {
			const response = await axios.post("https://mitserver.app:2409/upload", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			if (response.status === 200) {
				closeUploadModal();
			}
		} catch (error) {
			console.error("Error al subir imágenes:", error);
		}
	};

	const openViewer = (index) => {
		setCurrentImage(index);
		setViewerOpen(true);
	};

	const closeViewer = () => {
		setViewerOpen(false);
	};

	return (
		<PhotosContainer>
			<ButtonContainer>
				<img src="/assets/uploadPhotos.png" alt="Subir Fotos" style={{ width: "50px", cursor: "pointer" }} onClick={openUploadModal} />
				<img
					src="/assets/showPhotos.png"
					alt="Ver Fotos"
					style={{ width: "50px", cursor: "pointer" }}
					onClick={() => {
						toast("Galeria activa el 23 de septiembre.", {
							style: {
								fontFamily: "Great Vibes",
								fontSize: "1.5rem",
								backgroundColor: "#556B2F",
								color: "#FFD700",
								textAlign: "center",
							},
							progressStyle: {
								background: "#FFD700",
							},
							autoClose: 5000,
						});
						toast(
							<a
								href="https://violetxv.life"
								style={{ color: "#FFD700", textDecoration: "none" }}
								target="_blank"
								rel="noopener noreferrer">
								https://violetxv.life
							</a>,
							{
								style: {
									fontFamily: "Great Vibes",
									fontSize: "1.5rem",
									backgroundColor: "#556B2F",
									color: "#FFD700",
									textAlign: "center",
								},
								progressStyle: {
									background: "#FFD700",
								},
								autoClose: 8000,
							}
						);
					}}
				/>
			</ButtonContainer>
			<Image src="/assets/photos.png" alt="Photos Icon" />
			<FotosText>
				Queremos que todos disfruten y compartan los momentos especiales de la fiesta. En esta sección pueden subir y ver fotos del evento.
				¡Nos encantaría que todos compartan sus recuerdos para que podamos revivir juntos cada instante inolvidable! Solo puedes subir 10
				fotos en posición vertical así que asegúrate de subir las mejores!
			</FotosText>

			{/* Modal para subir fotos */}
			<Modal
				isOpen={isUploadModalOpen}
				onRequestClose={closeUploadModal}
				style={{
					content: {
						top: "50%",
						left: "50%",
						right: "auto",
						bottom: "auto",
						marginRight: "-50%",
						transform: "translate(-50%, -50%)",
						backgroundColor: "rgba(0,0,0,0.8)",
						color: "white",
						padding: "20px",
						borderRadius: "10px",
					},
				}}>
				<CloseButton onClick={closeUploadModal}>&times;</CloseButton>
				<h2>Subir Imágenes</h2>
				<ThumbnailContainer>
					{uploadedImages.map((image, index) => (
						<Thumbnail key={index} src={image} alt={`Imagen ${index + 1}`} />
					))}
					{uploadedImages.length < 10 && (
						<EmptyThumbnail>
							<input type="file" accept="image/*" onChange={handleFileChange} style={{ display: "none" }} id="fileInput" />
							<label htmlFor="fileInput" style={{ cursor: "pointer" }}>
								+
							</label>
						</EmptyThumbnail>
					)}
				</ThumbnailContainer>
				<img
					src="/assets/uploadPhotos.png"
					alt="Subir Fotos"
					style={{
						width: "40px",
						cursor: "pointer",
						alignSelf: "center",
						display: "block",
						margin: "0 auto",
					}}
					onClick={handleSubmit}
				/>
			</Modal>

			{/* Modal para galería de fotos */}
			<Modal
				isOpen={isGalleryModalOpen}
				onRequestClose={closeGalleryModal}
				style={{
					content: {
						top: "50%",
						left: "50%",
						right: "auto",
						bottom: "auto",
						marginRight: "-50%",
						transform: "translate(-50%, -50%)",
						backgroundColor: "rgba(0,0,0,0.8)",
						color: "white",
						padding: "20px",
						borderRadius: "10px",
					},
				}}>
				<CloseButton onClick={closeGalleryModal}>&times;</CloseButton>
				<h2>Galería de Fotos</h2>
				<Gallery
					photos={uploadedImages.map((src, index) => ({
						src,
						width: 4,
						height: 3,
						key: index.toString(),
					}))}
					onClick={(event, { index }) => openViewer(index)}
				/>

				<Viewer
					visible={isViewerOpen}
					onClose={closeViewer}
					images={uploadedImages.map((src) => ({ src }))}
					activeIndex={currentImage}
					onMaskClick={closeViewer}
				/>
			</Modal>
		</PhotosContainer>
	);
};

export default Fotos;
