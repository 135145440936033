import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// AsyncStorage.clear();
const LoginPageContainer = styled.div`
	position: relative;
	width: 100vw;
	height: 100vh;
	background-image: url("/assets/bg3.png");
	background-size: cover;
	background-position: center;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	flex-direction: column;
	background-color: "green";
`;

const LoginTitle = styled.h2`
	font-family: "Great Vibes", cursive;
	color: #fff;
	font-size: 2.5rem;
	margin-bottom: 20px;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
`;

const Input = styled.input`
	padding: 10px;
	font-size: 1.2rem;
	margin-bottom: 10px;
	border: 2px solid #ccc;
	border-radius: 5px;
	width: 250px;
	text-align: center;
`;

const Button = styled.button`
	padding: 10px 20px;
	font-size: 1.2rem;
	cursor: pointer;
	border: none;
	border-radius: 5px;
	background-color: #333;
	color: #fff;
	transition: background-color 0.3s ease;

	&:hover {
		background-color: #555;
	}
`;

const Title = styled.h1`
	font-size: 6rem;
	font-family: "Great Vibes", cursive;
	color: white;
	text-shadow: 2px 2px 8px gold;
	margin: 0;
	position: absolute;
	top: 8vh;
	transform: translateY(-50%);

	@media (max-width: 600px) {
		font-size: 4rem;
	}
`;

const bounceAnimation = keyframes`
  0%, 20%, 50%, 80%, 100% { 
    transform: translateY(0); 
  }
  40% { 
    transform: translateY(-10px); 
  }
  60% { 
    transform: translateY(-5px); 
  }
`;

const FloatingButton = styled.img`
	width: 80px; /* Tamaño del botón */
	margin-top: 20px;
	cursor: pointer;
	animation: ${bounceAnimation} 2s infinite;
`;

const ErrorMessage = styled.p`
	color: red;
	margin-top: 10px;
	font-size: 1rem;
`;

const LoginPage = ({ onLogin }) => {
	const [code, setCode] = useState("");
	const [error, setError] = useState("");

	const handleLogin = async () => {
		try {
			const response = await axios.post("https://mitserver.app:2409/login", { code });
			console.log("RESPUESTA", response);
			if (response.status === 200) {
				toast("Bienvidenid@ " + response.data.data.name, {
					style: {
						fontFamily: "Great Vibes",
						fontSize: "2rem",
						backgroundColor: "#556B2F",
						color: "#FFD700",
						textAlign: "center",
					},
					progressStyle: {
						background: "#FFD700",
					},
					autoClose: 5000,
				});
				let data = response.data;
				data = data.data;
				const qty = data.qty;
				const code = data.code;
				await AsyncStorage.setItem("qty", qty);
				await AsyncStorage.setItem("code", code);
				onLogin(true);
			}
		} catch (error) {
			setError("Código incorrecto");
			toast("Código Incorrecto", {
				style: {
					fontFamily: "Great Vibes",
					fontSize: "2rem",
					backgroundColor: "#556B2F", // Verde musgo
					color: "#FFD700", // Dorado
					textAlign: "center", // Centrar el texto
				},
				progressStyle: {
					background: "#FFD700",
				},
				autoClose: 5000,
			});
		}
	};

	return (
		<LoginPageContainer>
			<Title>XV Violet</Title>
			<LoginTitle>Ingresa el código de acceso</LoginTitle>
			<Input type="text" value={code} onChange={(e) => setCode(e.target.value)} placeholder="Código" />
			<FloatingButton src="/assets/loginSeal.png" alt="Botón Flotante" onClick={handleLogin} />
			{/* <Button onClick={handleLogin}>Ingresar</Button> */}
			{error && <ErrorMessage>{error}</ErrorMessage>}
		</LoginPageContainer>
	);
};

export default LoginPage;
